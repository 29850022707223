<template>
	<div class="bullMarket-order">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-select v-model:value="fenceSearch.fcs_pay_status">
					<a-select-option value="">fcs状态查询</a-select-option>
					<a-select-option value="0">未处理</a-select-option>
					<a-select-option value="1">处理中</a-select-option>
					<a-select-option value="2">已支付</a-select-option>
					<a-select-option value="3">未支付</a-select-option>
					<a-select-option value="4">人工查错</a-select-option>
				</a-select>
				 <a-range-picker v-model:value="fenceSearch.time" />
				<a-input-search style="width: 400px;" v-model:value="fenceSearch.key" placeholder="关键字搜索" enter-button @search="getOrderList(1,info.limit)" />
			</a-space>
		</div>
		<div class="el-content">
			<a-tabs v-model:activeKey="fenceSearch.status" @change="getOrderList(1,info.limit)">
				<a-tab-pane key="0" tab="全部"></a-tab-pane>
				<a-tab-pane key="1" tab="待支付"></a-tab-pane>
				<a-tab-pane key="2" tab="已支付"></a-tab-pane>
				<a-tab-pane key="3" tab="已取消"></a-tab-pane>
				<a-tab-pane key="4" tab="已确认"></a-tab-pane>
				<a-tab-pane key="5" tab="已退款"></a-tab-pane>
			</a-tabs>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'订单编号',dataIndex:'order_number'},
				{title:'用户信息',dataIndex:'account',slots:{customRender:'account'}},
				{title:'卖家信息',dataIndex:'sale',slots:{customRender:'sale'}},
				{title:'订单价格',dataIndex:'price',slots:{customRender:'price'}},
				{title:'订单状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'FCS状态',dataIndex:'fcs',slots:{customRender:'fcs'}},
				{title:'创建时间',dataIndex:'update_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #account="{record}">
					<div style="display: flex;align-items: center;" v-if="record.account">
						<a-avatar :src="record.account.avatar_url" style="margin-right: 8px;"/>
						<div>
							<div><span class="f12">user_id：</span> {{record.account.user_id}}</div>
							<div><span class="f12">用户昵称：</span>{{record.account.nickname}}</div>
							<div><span class="f12">联系电话：</span>{{record.account.mobile}}</div>
						</div>
					</div>
				</template>
				<template #sale="{record}">
					<div style="display: flex;align-items: center;"  v-if="record.seller && record.seller.user">
						<a-avatar :src="record.seller.user.avatar_url" style="margin-right: 8px;"/>
						<div>
							<div><span class="f12">user_id：</span>{{record.seller.user.user_id}}</div>
							<div><span class="f12">卖家昵称：</span>{{record.seller.user.nickname}}</div>
							<div><span class="f12">联系电话：</span>{{record.seller.user.mobile}}</div>
						</div>
					</div>
				</template>
				<template #price="{record}">
					<div><span class="f12">订单价格：</span>{{record.total_price}}</div>
					<div><span class="f12">实际价格：</span>{{record.true_price}}</div>
				</template>
				<template #status="{record}">
					<a-tooltip>
						<template #title>
							<div><span class="f12">支付时间：</span>{{record.pay_time}}</div>
							<div><span class="f12">支付方式：</span>
								<span>{{$util.payMethod(record.pay_method)}}</span>
							</div>
						</template>
						<a-tag color="#00CC66" v-if="record.pay_status == 1">已支付</a-tag>
					</a-tooltip>
					<a-tag color="#999" v-if="record.pay_status == 0">未支付</a-tag>
					<a-tag type="#999" v-if="record.is_cancel == 1">已取消</a-tag>
					<a-tag type="#00CC66" v-if="record.is_confirm == 1">已确认</a-tag>
					<a-tooltip>
						<template #title>
							<div><span class="f12">退款申请时间：</span>{{record.refund_apply_time}}</div>
							<div><span class="f12">退款时间：</span>{{record.refund_time}}</div>
							<div><span class="f12">退款原因：</span>{{record.refund_reason}}</div>
						</template>
						<a-tag color="#FF0066" v-if="record.is_refund == 2">已退款</a-tag>
					</a-tooltip>
				</template>
				<template #fcs="{record}">
					<a-tag color="#999" v-if="record.fcs_pay_status == 0">未处理</a-tag>
					<a-tag color="#00CCCC" v-if="record.fcs_pay_status == 1">处理中</a-tag>
					<a-tag color="#00CC66" v-if="record.fcs_pay_status == 2">已支付</a-tag>
					<a-tag color="#999" v-if="record.fcs_pay_status == 3">未支付</a-tag>
					<a-tag color="#FF0066" v-if="record.fcs_pay_status == 4">人工查错</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<a-button size="small" type="primary" @click="retry(record.id)">重试</a-button>
						<router-link :to="{path:'/adhibition/bullMarket/supervise',query:{re_id:record.id,re_ty:'animal_order'}}">
							<a-button size="small" type="primary">关联</a-button>
						</router-link>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getOrderList(info.page,e)}"
					@change="(e)=>{getOrderList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{
	setup(){
		const state = reactive({
			fenceSearch:{
				key:'',
				time:[],
				status:'0',
				fcs_pay_status:''
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			}
		})
		getOrderList(1,state.info.limit)

		function getOrderList(page,limit){
			bullModel.getBullOrder(page,limit,state.fenceSearch,res=>state.info = {limit,...res})
		}

		const retry = id=>bullModel.bullOrderReTry(id,()=>{
			getOrderList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getOrderList,
			retry
		}
	}
}
</script>

<style lang="scss">
	.bullMarket-order{
		.el-tabs--border-card{
			box-shadow: none;
		}
	}
</style>
